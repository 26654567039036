// The moviedb API
const API_URL = 'https://api.themoviedb.org/3';

const IMAGE_BASE_URL =  'https://image.tmdb.org/t/p';

const API_KEY = '72e802f0e9744d18a3ef5fd01f6c7231';

const BACKDROP_SIZE = 'w1280';

const POSTER_SIZE = 'w500';

// Emailjs 
const MAIL_API_KEY = "user_BOS5IXKTlO290ZGLOt59L";

const SERVICE_ID = "service_po4jpgd";

const TEMPLATE_KEY = "template_mh00olq";

export {
    API_URL,
    IMAGE_BASE_URL,
    API_KEY,
    BACKDROP_SIZE,
    POSTER_SIZE,
    MAIL_API_KEY,
    SERVICE_ID,
    TEMPLATE_KEY
}